import React from 'react'

const Footer = () => (
  <footer>
    <div className="container">
      <div className="g2">
        <div>
          <p className="h">Otevírací doba</p>

          <p>
            Pondělí až neděle<br/>
            <strong>8:00 - 20:00</strong>
          </p>
        </div>
        <div>
          <p className="h">Masážní salon Vimperk</p>

          <p>
            Mírová 469<br/>
            385 01, Vimperk
          </p>

          <p>
            <a className="link" href="tel:+420776009508">Volejte 776 009 508</a>
          </p>
        </div>
      </div>

    </div>
    <div className="container footnote">
      <p>{`© ${new Date().getFullYear()}`} Masážní Salon Dimension &middot; Mária Vacíková</p>
    </div>
  </footer>
)

export default Footer
