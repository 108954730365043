import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import logo from '../images/logo3.jpg'

const Header = ({ siteTitle }) => (
  <header>
    <div className="container lg-only">
      <Link to="/">
        <img id="logo" src={logo} alt="Masážní salón Dimension" />
      </Link>

      <ul id="menu">
        <li>
          <Link activeClassName="active" to="/sluzby">
            Služby
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/workshopy">
            Workshopy
          </Link>
        </li>
        <li>
          <Link activeClassName="active" to="/kontakt">
            Kontakt
          </Link>
        </li>
      </ul>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: 'Másážní salón Dimension Vimperk',
}

export default Header
